<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtipotransporte-modals
			:modelName="modelName"
		></mtipotransporte-modals>
	</div>
</template>

<script>
import mtipotransporteActions from './MtipotransporteActions';
import mtipotransporteModals from './MtipotransporteModals.vue';

export default {
	name: 'mtipotransporte-grid',
	components: {
		'mtipotransporte-modals': mtipotransporteModals
	},
	data() {
		return {
			modelName: 'mtipotransporte',
			actions: mtipotransporteActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
